import axios from "axios";

const baseUrl = process.env.REACT_APP_CARS_SERVICE_BASE_URL;
const token = localStorage.getItem("access_token");

export const getCountries = async () => {
    let response;
    await axios
        .get(`${baseUrl}/api/v1/countries`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            response = res.data.data;
        })
        .catch((e) => {
            console.log("Error:", e);
            response = {};
        });
    return response;
};

export const getCities = async (country: string) => {
    console.log("country:", country);
    let response;
    await axios
        .get(`${baseUrl}/api/v1/country/cities/${country}`, {
            headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
            response = res.data.data;
        })
        .catch((e) => {
            console.log("Error:", e);
            response = {};
        });

    return response;
};

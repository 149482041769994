import { Box, Flex, Heading, Text } from "@chakra-ui/react";

export default function RideReceipt(props: any) {
  const { ride } = props;
  return (
    <Flex
      maxW="100%"
      w="100%"
      mx={{ base: "auto", lg: "0px" }}
      me="auto"
      h="100%"
      alignItems="left"
      justifyContent="center"
      mb={{ base: "30px", md: "10px" }}
      px={{ base: "25px", md: "0px" }}
      //   mt={{ base: "20px", md: "0vh" }}
      mt={3}
      flexDirection="column"
      flexWrap="nowrap"
      sx={{
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Ride Status</Heading>
        <Text color={ride.ride_status === "CANCELED" ? "red" : "green"}>{ride?.ride_status}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Pickup Location</Heading>
        <Text textTransform="capitalize">{ride?.pickup_location?.place}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Destination Location</Heading>
        <Text textTransform="capitalize">{ride?.destination_location?.place}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Driver Name</Heading>
        <Text textTransform="capitalize">{ride?.driver?.driver_name}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Car Type</Heading>
        <Text>{ride?.car_type}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Car Registration Number</Heading>
        <Text>{ride?.car?.car_registration_number}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Fare Amount</Heading>
        <Text>{ride?.fare_amount}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Discount Amount</Heading>
        <Text>{ride.discount ? ride.discount.discount_amount : 0}</Text>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 3 }}>
        <Heading size="sm">Discounted Fare Amount</Heading>
        <Text>{ride.discount ? ride.discount.discount_amount : ride.fare_amount}</Text>
      </Box>
    </Flex>
  );
}

import { SystemStyleObject } from "@chakra-ui/styled-system";
import { mode } from "@chakra-ui/theme-tools";

interface SliderStyles {
  components: {
    RangeSlider: {
      variants: {
        main: (props: Record<string, any>) => {
          thumb: SystemStyleObject;
        };
      };
    };
  };
}

export const sliderStyles: SliderStyles = {
  components: {
    RangeSlider: {
      variants: {
        main: (props) => ({
          thumb: {
            bg: mode("brand.500", "brand.400")(props),
          },
        }),
      },
    },
  },
};

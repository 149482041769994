import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import login from "../../../assets/images/main/TeracarLogo.png";

interface SidebarBrandProps {}

const SidebarBrand: React.FC<SidebarBrandProps> = () => {

  return (
    <Flex align="center" direction="column" background={{ background: "brand.800" }}>
      <Image src={login}  height={100} objectFit={'cover'} width={'80%'} mt={5}/>
    </Flex>
  );
};

export default SidebarBrand;

import React, { useEffect, useState } from "react";
import { Box, Flex, Portal, Text, useDisclosure } from "@chakra-ui/react";
import AdminLayout from "../../layouts/admin/index";
import Navbar from "../../components/navbar/NavbarAdmin";
import SubscriptionPlan from "../../components/subscription/subscription";
import BasicPlan from "../../assets/images/layout/Cycle.png";
import PremiumPlan from "../../assets/images/layout/Car.png";
import VipPlan from "../../assets/images/layout/rocket.png";
import TrialPlan from "../../assets/icons/walk.svg";
import {
  checkSubscription,
  createSubscription,
  getSubscriptionList,
  manageAccounts,
} from "../../services/user";
import { showErrorToast, showSuccessToast } from "../../utils/toast";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { jwtDecode } from "jwt-decode";

interface SubscriptionItem {
  id: string;
  name: string;
  description: string;
  images: string[];
  features: { name: string }[];
  metadata: any;
  prices: Price[];
}

interface Price {
  id: string;
  currency: string;
  unit_amount: string;
}

interface PaymentSuccessResponse {
  paymentStatus: string;
  message: string;
  priceId: string;
  planId: string;
  noOfLicense: number;
  amount: number;
  planActived: boolean;
  currency: string;
  interval: string;
  startDate: string;
  endDate: string;
  isTrial: boolean;
  isTrialExpired: boolean;
  planType: string;
  isPaymentFailed: boolean;
}

const SubscriptionDashboard: React.FC = () => {
  const { onOpen } = useDisclosure();
  const { list: subscriptionList, paymentDetails } = useAppSelector(
    (state: RootState) => state.persist.subs
  );
  const { user } = useAppSelector((state: RootState) => state.persist.auth);
  const [isPaymentPaid, setPaymentPaid] = useState<PaymentSuccessResponse>(paymentDetails);
  const [isSubscribeList, setSubscribeList] = useState<SubscriptionItem[]>(subscriptionList);
  const [isLoader, setisLoader] = useState(false);
  const [isIndex, setIsIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const token = localStorage.getItem("access_token");

  const BgColor = [
    "linear-gradient(325deg, rgb(107 67 156) 60%, rgb(10 26 27) 98%)",
    "linear-gradient(325deg, rgba(42,176,252,1) 60%, rgba(73,196,200,1) 98%)",
    "linear-gradient(325deg, rgba(255,74,137,22) 60%, rgb(255 121 117) 98%)",
    "linear-gradient(325deg, rgb(82 94 246) 60%, rgb(155 87 238) 98%)",
  ];
  const boxShadow = ["#6b439c", "#2ab0fc", "#ff4a89", "#525ef6"];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("paymentStatus");
    if (status === "failed") {
      showErrorToast("Payment Failed", true);
      queryParams.delete("paymentStatus");
      const newUrlWithoutPaid = `${window.location.origin}${window.location.pathname}${queryParams}`;
      window.history.replaceState({}, document.title, newUrlWithoutPaid);
    }
    if (subscriptionList) {
      setSubscribeList(subscriptionList.slice(0, 4).reverse());
    }
    if (paymentDetails) {
      setPaymentPaid(paymentDetails);
    }
  }, [paymentDetails, subscriptionList]);

  useEffect(() => {
    const fetchData = async () => {
      await getSubscriptionList(dispatch);
      await checkSubscription(dispatch);
      await manageAccounts(dispatch);

      const queryParams = new URLSearchParams(window.location.search);
      const status = queryParams.get("paymentStatus");
      if (status === "success") {
        showSuccessToast("Payment successful and plan activated", true);
        queryParams.delete("paymentStatus");
        const newUrlWithoutPaid = `${window.location.origin}${window.location.pathname}${queryParams}`;
        window.history.replaceState({}, document.title, newUrlWithoutPaid);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleSubscribe = async (price: string, index: number, plan: string) => {
    setIsIndex(index);
    setisLoader(true);
    const decodedToken: any = jwtDecode(token ? token : "");
    console.log("decoded token", decodedToken);
    const values = {
      userId: decodedToken._id,
      name: decodedToken.name,
      priceId: price,
      email: decodedToken.email,
      planName: plan,
      customerId: decodedToken.customerId,
      countryCode: decodedToken.countryCode,
    };
    console.log("values on handle subscribe ----- ", values);

    try {
      const createSubs = await createSubscription(values);
      const subscriptionLink = createSubs.checkoutUrl;
      window.location.href = subscriptionLink;
      setisLoader(false);
    } catch (error) {
      setisLoader(false);
    }
  };

  // console.log("is payment paid", isPaymentPaid);
  // console.log("isSubscriptionList -- ", isSubscribeList);
  return (
    <AdminLayout>
      <Portal>
        <Box>
          <Navbar onOpen={onOpen} brandText={"Subscription"} />
        </Box>
      </Portal>
      <Flex
        pt={{ base: "130px", md: "80px", xl: "80px" }}
        align="center"
        justify="center"
        direction="column"
        height="100vh"
      >
        <Text fontSize={21}>Choose a subscription plan</Text>
        <Flex flexDirection="row" alignItems="center">
          {isSubscribeList &&
            isSubscribeList.map((item, index) => (
              <SubscriptionPlan
                id={index}
                planType={isPaymentPaid?.planType}
                imageUrl={
                  index === 0
                    ? TrialPlan
                    : index === 1
                    ? BasicPlan
                    : index === 2
                    ? PremiumPlan
                    : VipPlan
                }
                bgColor={BgColor[index]}
                expiryDate={isPaymentPaid?.endDate}
                isPaidOne={isPaymentPaid?.paymentStatus === "paid"}
                isTrial={!isPaymentPaid?.isTrialExpired}
                isPaid={
                  isPaymentPaid?.paymentStatus === "paid" &&
                  isPaymentPaid?.priceId === item?.prices[0].id
                }
                features={item?.features.map((feature) => feature.name)}
                name={item?.name}
                description="Access to basic features"
                amount={parseFloat(item?.prices[0].unit_amount)}
                onSubscribe={() => handleSubscribe(item?.prices[0].id, index, item.name)}
                boxShadowColor={boxShadow[index]}
                isLoader={index === isIndex && isLoader}
                styleTop={index === 0 || index === 2 ? "6.3rem" : index === 1 ? "9rem" : "0rem"}
              />
            ))}
        </Flex>
      </Flex>
    </AdminLayout>
  );
};

export default SubscriptionDashboard;

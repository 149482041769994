import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import theme from "./theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/Auth/signin";
import SignUp from "./pages/Auth/signup";
import MainDashboard from "./pages/Dashboard/main";
import ProfileDashboard from "./pages/Profile";
import SubscriptionDashboard from "./pages/Subscription";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store, persistor, RootState } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import ValidateOTP from "./pages/Auth/signin/ValidateOTP";
import { useSelector } from "react-redux";
import EmployeeDashboard from "./pages/Employee";
import AddEmployee from "./pages/Employee/AddEmployee";
import CardDashboard from "./pages/Card";
import EmployeeRidesDashboard from "./pages/RidesHistory";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);
const token = localStorage.getItem("access_token");
const loginToken = localStorage.getItem("login_token");

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ChakraProvider theme={theme}>
          <React.StrictMode>
            <ThemeEditorProvider>
              <ColorModeScript />
              <Router>
                <Routes>
                  {!token ? (
                    <>
                      <Route path="/" element={<SignIn />} />
                      <Route path="/sign-up" element={<SignUp />} />
                      <Route path="*" element={<Navigate to="/" />} />
                      <Route path="/verify-login-otp" element={<ValidateOTP />} />
                    </>
                  ) : (
                    <>
                      <Route path="/" element={<Navigate to="/employee" />} />
                      {/* <Route path="/home" element={<MainDashboard />} /> */}
                      <Route path="/profile" element={<ProfileDashboard />} />
                      <Route path="/subscription" element={<SubscriptionDashboard />} />
                      <Route path="/employee" element={<EmployeeDashboard />} />
                      <Route path="/addEmployee" element={<AddEmployee />} />
                      <Route path="/manage-cards" element={<CardDashboard />} />
                      <Route
                        path="/employee-rides/:employeeId"
                        element={<EmployeeRidesDashboard />}
                      />
                    </>
                  )}
                </Routes>
              </Router>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </ThemeEditorProvider>
          </React.StrictMode>
        </ChakraProvider>
      </PersistGate>
    </Provider>
  );
};

root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

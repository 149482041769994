import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import { ridesServiceBaseUrl } from "../../globals/constants";
import { useParams } from "react-router-dom";
import { ViewIcon } from "@chakra-ui/icons";
import RideReceipt from "./RideReceipt";

const RidesTable: React.FC = () => {
  const [ridesData, setRidesData] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentRide, setCurrentRide] = useState(null);
  const [pageIndex, setPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 5;
  const token = localStorage.getItem("access_token");
  const params = useParams();
  const employeeId: string | undefined = params?.employeeId;

  const fetchEmployeeRides = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${ridesServiceBaseUrl}/rides/rider/${employeeId}?page=1&limit=5`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      setRidesData(response.data.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchEmployeeRides();
  }, []);

  // const handlePreviousPage = () => {
  //   setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  // };

  // const handleNextPage = () => {
  //   setPageIndex((prevIndex) =>
  //     Math.min(prevIndex + 1, Math.ceil(employeesData.length / pageSize) - 1)
  //   );
  // };

  const paginatedData = ridesData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  const getFormattedDate = (dateTime: string) => {
    const timestamp = new Date(dateTime);
    const dateString = timestamp.toLocaleDateString();

    const [day, month, year] = dateString.split("/");

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];

    return `${monthName} ${day}, ${year}`;
  };

  const getFormattedTime = (dateTime: string) => {
    const timestamp = new Date(dateTime);
    return timestamp.toLocaleTimeString();
  };

  const openReceipt = (ride: any) => {
    setCurrentRide(ride);
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Flex
      pt={{ base: "130px", md: "80px", xl: "80px" }}
      align="start"
      justify="start"
      direction="column"
      height="100vh"
    >
      <Flex alignSelf="start" mb="20px">
        {ridesData && ridesData.length > 0 && `Employee - ${ridesData[0].rider_name}`}
      </Flex>

      <Modal isOpen={isOpen} onClose={handleClose} size="xl">
        <ModalOverlay />
        <ModalContent width="90%" maxW="600px" maxHeight="80vh">
          <ModalHeader borderBottom="1px solid lightgrey">Ride Receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto">
            <RideReceipt ride={currentRide} />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Table variant="striped" colorScheme="gray" minWidth="100%">
        <Thead>
          <Tr style={{ backgroundColor: "#422AFB" }}>
            <Th style={{ color: "#FFFFFF" }}>Pickup</Th>
            <Th style={{ color: "#FFFFFF" }}>Destination</Th>
            <Th style={{ color: "#FFFFFF" }}>Scheduled Date</Th>
            <Th style={{ color: "#FFFFFF" }}>Scheduled Time</Th>
            <Th style={{ color: "#FFFFFF" }}>Car Type</Th>
            <Th style={{ color: "#FFFFFF" }}>Fare</Th>
            <Th style={{ color: "#FFFFFF" }}>Status</Th>
            <Th style={{ color: "#FFFFFF" }}>Receipt</Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.length > 0 ? (
            paginatedData.map((ride: any) => (
              <Tr key={ride._id}>
                <Td style={{ textTransform: "capitalize" }}>{ride.pickup_location.place}</Td>
                <Td style={{ textTransform: "capitalize" }}>{ride.destination_location.place}</Td>
                <Td style={{ textTransform: "capitalize" }}>
                  {getFormattedDate(ride.scheduled_time)}
                </Td>
                <Td style={{ textTransform: "capitalize" }}>
                  {getFormattedTime(ride.scheduled_time)}
                </Td>
                <Td style={{ textTransform: "capitalize" }}>{ride.car_type}</Td>
                <Td>{ride.fare_amount}</Td>
                <Td>{ride.ride_status}</Td>
                <Td>
                  <ViewIcon cursor="pointer" onClick={() => openReceipt(ride)} />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={8} style={{ textAlign: "center" }}>
                No Rides Found
              </Td>
            </Tr>
          )}
        </Tbody>
        {/* <tfoot>
          <Tr>
            <Td colSpan={5} style={{ textAlign: "center" }}>
              <Button onClick={handlePreviousPage} disabled={pageIndex === 0}>
                Previous Page
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={(pageIndex + 1) * pageSize >= employeesData.length}
              >
                Next Page
              </Button>
            </Td>
          </Tr>
        </tfoot> */}
      </Table>
    </Flex>
  );
};

export default RidesTable;

import { extendTheme } from "@chakra-ui/react";

import { globalStyles } from "./styles";
import { inputStyles } from "./components/input";
import { buttonStyles } from "./components/button";
import { linkStyles } from "./components/link";
import { progressStyles } from "./components/progress";
import { sliderStyles } from "./components/slider";
import { textareaStyles } from "./components/textarea";
import { switchStyles } from "./components/switch";
import { badgeStyles } from "./components/badge";
import { breakpoints } from "./breakpoints";



export default extendTheme(
  breakpoints,
  globalStyles,
  badgeStyles,
  buttonStyles,
  inputStyles,
  linkStyles,
  progressStyles,
  sliderStyles,
  switchStyles,
  textareaStyles
);

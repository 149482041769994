import { Button, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import React, { useState } from "react";
import { FiLogOut } from "react-icons/fi";
import ConfirmationModal from "../../confirmBox/ConfirmBox";
import { userLogout } from "../../../utils/forms";
import { useAppDispatch } from "../../../store/hooks";

interface SidebarDocsProps {}

const SidebarDocs: React.FC<SidebarDocsProps> = () => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const colorMode = useColorModeValue("light", "dark");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    setIsConfirmOpen(true);
  };

  const handleLogoutConfirmed = () => {
    setIsConfirmOpen(false);
    userLogout(isMobile[0], dispatch);
  };

  return (
    <div>
      <Button
        onClick={handleLogout}
        variant="link"
        color={colorMode === "light" ? "grey.800" : "white"}
        leftIcon={<FiLogOut />}
        ml="24px"
        _hover={{
          textDecoration: "none",
          color: colorMode === "light" ? "black" : "white",
        }}
      >
        Logout
      </Button>
      <ConfirmationModal
        isOpen={isConfirmOpen}
        onClose={() => setIsConfirmOpen(false)}
        onConfirm={handleLogoutConfirmed}
        isLogoutModal={true}
      />
    </div>
  );
};

export default SidebarDocs;

import React, { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Portal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import AdminLayout from "../../layouts/admin/index";
import Navbar from "../../components/navbar/NavbarAdmin";
import Card from "../../components/card/Card";
import banner from "../../assets/images/auth/banner.png";
import { EditIcon } from "@chakra-ui/icons";
import { useProfileForm } from "../../utils/forms";
import { useAppDispatch } from "../../store/hooks";
import FixedPlugin from "../../components/fixedPlugin/FixedPlugin";
interface Country {
  country_code: string;
  country_name: string;
}

interface Region {
  city_name: string,
  value: string,
  country_code: string,
}

const ProfileDashboard: React.FC = () => {
  const { onOpen } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("red", "red");
  const borderColor = useColorModeValue("white !important", "#111C44 !important");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useAppDispatch();
  const formik = useProfileForm(isMobile[0], dispatch);
  const countryCodes = ["+18", "+44", "+91", "+234", "+94"];
  const [formSubmitted, setFormSubmitted] = useState(false);
  const countries = [
    {
      country_code: "France",
      country_name: "France",
      value: 'france'
    },
    {
      country_code: "UK",
      country_name: "United Kingdom",
      value: 'united-kingdom'
    },
  ];
  const region = [
    {
      city_name: "Ile De France",
      value: 'ile-de-france',
      country_code: "France",
    },
    {
      city_name: "London boroughs",
      value: 'london-boroughs',
      country_code: "UK",
    },
  ]
  const handleEdit = () => {
    setIsEditing(true);
    setFormSubmitted(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormSubmitted(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
    setIsEditing(false);
    setFormSubmitted(false);
  };

  const handleOptionChange = (value: string) => {
    let rideState = false;
    if (value == "yes") {
      rideState = true;
    } else {
      rideState = false;
    }
    // formik.setFieldValue('areRidesAllowed', rideState);
  };
  return (
    <>
      <AdminLayout>
        <Portal>
          <Box>
            <Navbar onOpen={onOpen} brandText={"Profile"} />
          </Box>
        </Portal>
        <Box >
          <Flex justifyContent="center" alignItems="center" mt="5rem">
            <Card mb={{ base: "0px", lg: "20px" }} width="100%" h="auto">
              <Box
                bg={`url(${banner})`}
                bgSize="cover"
                borderRadius="16px"
                h="131px"
                w="100%"
                position="relative"
              >
                {!isEditing && (
                  <Button
                    position="absolute"
                    top="78px"
                    right="10px"
                    onClick={handleEdit}
                    zIndex="1"
                    backgroundColor="none"
                    border="none"
                  >
                    <EditIcon />
                  </Button>
                )}
              </Box>
              <Avatar
                mx="auto"
                h="87px"
                w="87px"
                mt="-120px"
                ml="42%"
                border="4px solid"
                borderColor={borderColor}
                zIndex="0"
                backgroundColor={'grey'}
              />
              <Flex w="max-content" mx="auto" mt="26px" direction="column">
                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Name <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Enter your company name"
                      mb={formSubmitted && formik.errors.name ? "0px" : "24px"}
                      fontWeight="500"
                      size="lg"
                      defaultValue={formik.values.name}
                      value={formik.values.name}
                      onChange={formik.handleChange("name")}
                      isDisabled={!isEditing}
                    />
                    {formSubmitted && formik.errors.name && (
                      <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                        {formik.errors.name}
                      </Text>
                    )}
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Phone number<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="9876543210"
                      mb={formSubmitted && formik.errors.phone ? "0px" : "24px"}
                      fontWeight="500"
                      size="lg"
                      defaultValue={formik.values.phone}
                      value={formik.values.phone}
                      onChange={formik.handleChange("phone")}
                      isDisabled={true}
                    />
                    {formSubmitted && formik.errors.phone && (
                      <Text
                        marginBottom={"24px"}
                        marginTop={"-24px"}
                        marginLeft={"10px"}
                        fontSize={14}
                        color="red"
                      >
                        {formik.errors.phone}
                      </Text>
                    )}
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Email <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="email"
                      placeholder="mail@gmail.com"
                      mb={formSubmitted && formik.errors.email ? "0px" : "24px"}
                      fontWeight="500"
                      size="lg"
                      defaultValue={formik.values.email}
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      isDisabled={true}
                    />
                    {formSubmitted && formik.errors.email && (
                      <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                        {formik.errors.email}
                      </Text>
                    )}
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Address <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Input
                      variant="auth"
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="text"
                      placeholder="Enter company address"
                      mb={formSubmitted && formik.errors.address ? "0px" : "24px"}
                      fontWeight="500"
                      size="lg"
                      defaultValue={formik.values.address}
                      value={formik.values.address}
                      onChange={formik.handleChange("address")}
                      isDisabled={!isEditing}
                    />
                    {formSubmitted && formik.errors.address && (
                      <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                        {formik.errors.address}
                      </Text>
                    )}
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Country <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Select
                      fontSize="sm"
                      variant="auth"
                      defaultValue={''}
                      fontWeight="500"
                      size="lg"
                      borderWidth="1px"
                      borderColor="gray.300"
                      borderRadius="15"
                      isDisabled={true}
                      mb={formSubmitted && formik.errors.countryCode ? "0px" : "24px"}
                      value={formik.values.countryCode} 
                      onChange={(e) => formik.setFieldValue('countryCode', e.target.value)}
                    >
                      {countries?.map((country, index) => (
                        <option key={index} value={(country as unknown as Country).country_name}> 
                          {(country as unknown as Country).country_name}
                        </option>
                      ))}
                    </Select>
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Region<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Select
                      fontSize="sm"
                      variant="auth"
                      defaultValue={""}
                      fontWeight="500"
                      size="lg"
                      borderWidth="1px"
                      borderColor="gray.300"
                      borderRadius="15"
                      mb={formSubmitted && formik.errors.regionCode ? "0px" : "24px"}
                      value={formik.values.regionCode}
                      isDisabled={true}
                      onChange={(e) =>
                        formik.setFieldValue('regionCode', e.target.value.replace(/\s+/g, '-').toLowerCase())}
                    >
                      {region?.map((region, index) => (
                        <option
                          key={index}
                          value={(region as unknown as Region).value}
                          disabled={formik.values.regionCode === (region as unknown as Region).value}
                        >
                          {region.city_name}
                        </option>
                      ))}
                    </Select>
                    {/* <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Are Rides Allowed <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Checkbox
                      isChecked={formik.values.areRidesAllowed === true}
                      isDisabled={!isEditing}
                      onChange={() => {
                        formik.setFieldValue('areRidesAllowed', true);
                      }}
                    >
                      Yes
                    </Checkbox>
                    <Checkbox
                      isChecked={formik.values.areRidesAllowed === false}
                      isDisabled={!isEditing}
                      onChange={() => {
                        formik.setFieldValue('areRidesAllowed', false);
                      }}
                      ml={5} 
                    >
                      No
                    </Checkbox> */}
                  </FormControl>
                  {/* {formSubmitted && formik.errors.areRidesAllowed && (
                    <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                      {formik.errors.areRidesAllowed}
                    </Text>
                  )} */}
                  {isEditing && (
                    <Flex justifyContent="space-evenly" mt="20px" marginRight="10px">
                      <Button width="140px" mr={4} colorScheme="red" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button width="140px" colorScheme="green" type="submit">
                        Update
                      </Button>
                    </Flex>
                  )}
                </form>
                <FixedPlugin></FixedPlugin>
              </Flex>
            </Card>
          </Flex>
        </Box>
      </AdminLayout>
    </>
  );
};

export default ProfileDashboard;

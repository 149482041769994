
import React, { useState } from 'react';
import { Box} from '@chakra-ui/react';
import { SidebarContext } from '../../contexts/SidebarContext';
import Sidebar from '../../components/sidebar/Sidebar';
import routes from '../../routes';
import { useMediaQuery } from '@chakra-ui/react';
interface DashboardProps {
  children: React.ReactNode;
  [key: string]: any; 
}

export default function Dashboard({ children, ...rest }: DashboardProps): JSX.Element {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const isMobileSize = useMediaQuery('(max-width: 48em)');
  
  return (
    <Box>
      <Box>
        <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        {isMobileSize[0] ? null : (
          <Sidebar routes={routes}  {...rest} />
        )}
          <Box
            float='right'
            minHeight='100vh'
            height='100%'
            overflow='auto'
            position='relative'
            maxHeight='100%'
            w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
            transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
            transitionDuration='.2s, .2s, .35s'
            transitionProperty='top, bottom, width'
            transitionTimingFunction='linear, linear, ease'>
            <Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='100vh' pt='50px'>
              {children}
            </Box>
          </Box>
        </SidebarContext.Provider>
      </Box>
    </Box>
  );
};


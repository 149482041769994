import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";

interface SidebarLink {
  name: string;
  layout: string;
  path: string;
  icon?: React.ReactNode;
}

interface SidebarLinksProps {
  routes: SidebarLink[];
}

const SidebarLinks: React.FC<SidebarLinksProps> = ({ routes }) => {
  const location = useLocation();
  const activeColor = useColorModeValue("gray.700", "white");
  // const inactiveColor = useColorModeValue("secondaryGray.600", "secondaryGray.600");
  const activeIcon = useColorModeValue("brand.500", "white");
  const textColor = useColorModeValue("secondaryGray.500", "white");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const activeRoute = (routePath: string) => location.pathname.includes(routePath);

  const renderLink = (link: SidebarLink, index: number) => (
    <NavLink key={index} to={`${link.path}`}>
      <Box>
        <HStack spacing={activeRoute(link.path) ? "22px" : "26px"} py='5px' ps='10px'>
          <Flex w='100%' alignItems='center' justifyContent='center'>
            {link.icon && (
              <Box color={activeRoute(link.path.toLowerCase()) ? activeIcon : textColor} me='18px'>
                {link.icon}
              </Box>
            )}
            <Text
              me='auto'
              color={activeRoute(link.path.toLowerCase()) ? activeColor : textColor}
              fontWeight={activeRoute(link.path.toLowerCase()) ? "bold" : "normal"}
            >
              {link.name}
            </Text>
          </Flex>
          <Box
            h='36px'
            w='4px'
            bg={activeRoute(link.path.toLowerCase()) ? brandColor : "transparent"}
            borderRadius='5px'
          />
        </HStack>
      </Box>
    </NavLink>
  );

  return <>{routes.map(renderLink)}</>;
};

export default SidebarLinks;

import React, { useEffect, useState } from "react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { stripeKeyUk, stripeKeyFrance } from "../../globals/constants";
import CardComponent from "./cardComponent";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";

const CardDashboard: React.FC = () => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  useEffect(() => {
    if (businessInfo.countryCode === "france") setStripePromise(loadStripe(stripeKeyFrance || ""));
    else setStripePromise(loadStripe(stripeKeyUk || ""));
  }, []);

  return (
    <>
      <Elements stripe={stripePromise}>
        <ElementsConsumer>
          {({ stripe, elements }) => <CardComponent stripe={stripe} elements={elements} />}
        </ElementsConsumer>
      </Elements>
    </>
  );
};

export default CardDashboard;

import axios from "axios";
import { baseURL } from "../globals/constants";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setAddress, setName } from "../slices/BusinessSlice";
interface BuisnssInfo {
  name: string;
  email: string;
  password: string;
  phone: string;
  address: string;
  registrationNumber: string;
  natureOfBusiness: string;
  countryCode: string;
  regionCode: string;
  areRidesAllowed: boolean;
}
export const getAllEmployees = async () => {
  const to = localStorage.getItem("access_token") || "";
  const decoded: { _id?: string } = jwtDecode(to);
  const businessId = decoded._id || "";
  try {
    const response = await axios.get(`${baseURL}/business/${businessId}/employees`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};
export const getToken = () => {
  const to = localStorage.getItem("access_token") || "";
  const decoded = jwtDecode(to) as BuisnssInfo;
  return decoded;
};

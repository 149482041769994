import { createContext } from "react";

interface SidebarContextProps {
  // Define your context properties here
  toggleSidebar: boolean;
  setToggleSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialSidebarContext: SidebarContextProps = {
  toggleSidebar: false,
  setToggleSidebar: () => {},
};

export const SidebarContext = createContext<SidebarContextProps>(initialSidebarContext);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SubscriptionItem {
  id: string;
  name: string;
  description: string;
  images: string[];
  features: any[];
  metadata: any;
  prices: Price[];
}

interface Price {
  id: string;
  currency: string;
  unit_amount: string;
}

interface PaymentSuccessResponse {
  paymentStatus: string;
  message: string;
  priceId: string;
  planId: string;
  noOfLicense: number;
  amount: number;
  planActived: boolean;
  currency: string;
  interval: string;
}


export interface SubscriptionState {
  list: SubscriptionItem[];
  paymentDetails: PaymentSuccessResponse | any;
  url: string;
  loading: boolean
}

const initialState: SubscriptionState = {
  list: [],
  paymentDetails: null,
  url: '',
  loading: false,
};


const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    fetchSubscriptionDataStart: (state) => {
      state.loading = true;
    },
    fetchSubscriptionList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchSubscriptionDetails: (state, action: PayloadAction<any>) => {
      state.paymentDetails = action.payload;
      state.loading = false;
    },
    fetchUpdateCards: (state, action: PayloadAction<any>) => {
      state.url = action.payload;
      state.loading = false;
    },
    fetchSubscriptionDataFailure: (state) => {
      state.loading = false;
    },
    fetchSubscriptionDataReset: (state) => {
      state.list = [];
      state.paymentDetails = null;
      state.url = '';
      state.loading = false;
    },
  },
});


export const { fetchUpdateCards, fetchSubscriptionDataReset, fetchSubscriptionDetails, fetchSubscriptionDataStart, fetchSubscriptionList, fetchSubscriptionDataFailure } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;

// Card.tsx
import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

interface CardProps extends BoxProps {
  children: React.ReactNode;
}

const Card: React.FC<CardProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Box
      borderRadius="16px"
      boxShadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
      p="20px"
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Card;

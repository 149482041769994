import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import DefaultAuth from "../../../layouts/auth/default";
// import illustration from "../../../assets/images/auth/logo_auth.jpg";
import illustration from "../../../assets/images/main/TeracarLogo.png";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import { useSignInForm } from "../../../utils/forms";
import { useAppDispatch } from "../../../store/hooks";

const SignIn: React.FC = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("red", "red");
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const dispatch = useAppDispatch();
  const formik = useSignInForm(isMobile[0], dispatch);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
  };

  const handleCheckboxChange = () => {
    const newValue = !keepLoggedIn;
    localStorage.setItem("isLogged", JSON.stringify(newValue));
    setKeepLoggedIn(newValue);
  };

  return (
    <DefaultAuth illustrationBackground={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@gmail.com"
                mb={formSubmitted && formik.errors.email ? "0px" : "24px"}
                fontWeight="500"
                size="lg"
                value={formik.values.email}
                onChange={formik.handleChange("email")}
              />
              {formSubmitted && formik.errors.email && (
                <Text
                  marginBottom={"24px"}
                  marginLeft={"10px"}
                  fontSize={14}
                  color="red"
                >
                  {formik.errors.email}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb={formSubmitted && formik.errors.password ? "0px" : "24px"}
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  value={formik.values.password}
                  onChange={formik.handleChange("password")}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeLine : RiEyeOffFill}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {formSubmitted && formik.errors.password && (
                <Text
                  marginBottom={"24px"}
                  marginLeft={"10px"}
                  fontSize={14}
                  color="red"
                >
                  {formik.errors.password}
                </Text>
              )}
              <Flex justifyContent="space-between" align="center" mb="24px">
                <FormControl display="flex" alignItems="center">
                  <Checkbox
                    id="remember-login"
                    colorScheme="brandScheme"
                    me="10px"
                    onChange={handleCheckboxChange}
                    isChecked={keepLoggedIn}
                  />
                  <FormLabel
                    htmlFor="remember-login"
                    mb="0"
                    fontWeight="normal"
                    color={textColor}
                    fontSize="sm"
                  >
                    Keep me logged in
                  </FormLabel>
                </FormControl>
                {/* <NavLink to="/auth/forgot-password">
                  <Text
                    color={textColorBrand}
                    fontSize="sm"
                    w="124px"
                    fontWeight="500"
                  >
                    Forgot password?
                  </Text>
                </NavLink> */}
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
                disabled={formik.isSubmitting}
              >
              {formik.isSubmitting ?  'Signing In': 'Sign In'} {formik.isSubmitting && <Spinner />}
              </Button>
            </FormControl>
          </form>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
};

export default SignIn;

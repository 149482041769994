import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  IconButton,
  Portal,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
  FormControl,
  FormLabel,
  ModalContent,
  Spinner,
} from "@chakra-ui/react";
import AdminLayout from "../../layouts/admin/index";
import Navbar from "../../components/navbar/NavbarAdmin";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { CardElement } from "@stripe/react-stripe-js";
import { baseURL, paymentServiceBaseUrl } from "../../globals/constants";
import axios from "axios";
import { showSuccessToast, showErrorToast } from "../../utils/toast";
import { updateBusinessRideStatus } from "../../services/user";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import ConfirmationModal from "../../components/confirmBox/ConfirmBox";

const CardComponent: React.FC<{ stripe: any; elements: any }> = ({ stripe, elements }) => {
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [userCards, setUserCards] = useState<any>(null);
  const [cardComplete, setCardComplete] = useState<boolean>(false);
  const [cardError, setCardError] = useState<string | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    fetchUserCards();
  }, []);

  const fetchUserCards = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${paymentServiceBaseUrl}/customer/payment_method/list`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      setUserCards(response.data.data);
    }
    setIsLoading(false);
  };

  const handleCardChange = (event: any) => {
    // Update the card complete state
    setCardComplete(event.complete);
    // Update the card error state
    setCardError(event.error ? event.error.message : null);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setButtonLoading(true);
    if (stripe && elements) {
      const cardElement = elements.getElement(CardElement);
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (stripeError) {
        console.error("Error creating payment method:", stripeError);
        showErrorToast("Problem Adding Card", true);
        onClose();
      } else {
        console.log("Payment method created:", paymentMethod);
        let token = localStorage.getItem("access_token");
        let payload = {
          paymentMethodId: paymentMethod?.id,
          saveAsDefaultPaymentMethod: true,
          setupFutureUsage: true,
        };
        const response = await axios.post(
          `${paymentServiceBaseUrl}/customer/payment_method/add`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response) {
          showSuccessToast("Card Added Successfully", true);
          fetchUserCards();
          onClose();
          const existingPaymentMethods = businessInfo?.paymentMethods;
          await updateBusinessRideStatus(dispatch, {
            paymentMethods:
              existingPaymentMethods.length > 0
                ? [...existingPaymentMethods, paymentMethod?.id]
                : [paymentMethod?.id],
          });
        } else {
          showErrorToast("Problem Adding Card", true);
          onClose();
        }
      }
    }
    setButtonLoading(false);
  };

  const handleDelete = async (paymentMethodId: string) => {
    const payload = {
      paymentMethodId: paymentMethodId,
    };
    const response = await axios.post(
      `${paymentServiceBaseUrl}/customer/payment_method/detach`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      showSuccessToast("Card Deleted Successfully", true);
      fetchUserCards();
    }
  };

  const removeCardsFromUser = async (paymentMethodId: string) => {
    await axios.patch(
      `${baseURL}/users/remove-payment-method/${paymentMethodId}`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  const handleOpenConfirmationModal = () => {
    setIsConfirmOpen(true);
  };

  const handleDeleteCardConfirmed = async (paymentMethodId: string) => {
    await handleDelete(paymentMethodId);
    setIsConfirmOpen(false);
    removeCardsFromUser(paymentMethodId);
  };

  return (
    <>
      <AdminLayout>
        <Portal>
          <Box>
            <Navbar onOpen={onOpen} brandText={"Manage Cards"} />
          </Box>
        </Portal>
        <Flex
          ml={6}
          mt={20}
          flexWrap="wrap"
          justifyContent={userCards?.length > 1 ? "space-evenly" : "flex-start"}
        >
          {userCards?.length > 0 ? (
            userCards?.map((card: any, index: number) => (
              <Box
                key={index}
                borderWidth="1px"
                borderRadius="lg"
                p="4"
                boxShadow="md"
                mb="4"
                mr="4"
                w={{ base: "100%", md: "calc(48% - 5px)" }}
                bg="white"
                _hover={{ transform: "scale(1.02)", transition: "transform 0.3s" }}
              >
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">Brand</Text>
                  <Text style={{ textTransform: "uppercase" }}>{card.card.brand}</Text>
                </Flex>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">Card Number</Text>
                  <Text>**** **** **** {card.card.last4}</Text>
                </Flex>
                <Flex justify="space-between" align="center" mb={2}>
                  <Text fontWeight="bold">Expiry</Text>
                  <Text>
                    {card.card.exp_month}/{card.card.exp_year}
                  </Text>
                </Flex>

                {/* Action buttons */}
                <Flex justifyContent="right" mt={4}>
                  <IconButton
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    size="sm"
                    ml={2}
                    onClick={handleOpenConfirmationModal}
                  />
                  <ConfirmationModal
                    isOpen={isConfirmOpen}
                    onClose={() => setIsConfirmOpen(false)}
                    onConfirm={() => handleDeleteCardConfirmed(card.id)}
                    isLogoutModal={false}
                  />
                </Flex>
              </Box>
            ))
          ) : (
            <Flex justifyContent="center" alignItems="center" h="70vh" w="100%">
              {isLoading ? <Spinner /> : <Text fontSize="2xl">No Cards Found</Text>}
            </Flex>
          )}
        </Flex>
        <div style={{ position: "fixed", bottom: "4rem", right: "2rem", zIndex: 9999 }}>
          <IconButton
            aria-label="Add"
            icon={<AddIcon />}
            colorScheme="teal"
            size="lg"
            onClick={onOpen}
          />
        </div>

        {/* Modal */}
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Card</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleSubmit}>
                <FormControl marginBottom="1rem">
                  <FormLabel>Card Details</FormLabel>
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#424770",
                          "::placeholder": {
                            color: "#aab7c4",
                          },
                        },
                        invalid: {
                          color: "#9e2146",
                        },
                      },
                      hidePostalCode: true,
                    }}
                    onChange={handleCardChange}
                  />
                </FormControl>
                {cardError && <Text color="red">{cardError}</Text>}
                {/*                                 
                                <button type="submit" >
                                    Pay
                                </button> */}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={onClose}>
                Close
              </Button>
              {buttonLoading ? (
                <Spinner />
              ) : (
                <Button colorScheme="green" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </AdminLayout>
    </>
  );
};

export default CardComponent;

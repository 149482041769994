import { Box, BoxProps } from "@chakra-ui/react";
import React, { CSSProperties } from "react";

interface RenderTrackProps {
  style: CSSProperties;
}

interface RenderThumbProps {
  style: CSSProperties;
}

interface RenderViewProps {
  style: CSSProperties;
}

export const renderTrack: React.FC<RenderTrackProps> = ({ style, ...props }) => {
  const trackStyle: CSSProperties = {
    position: "absolute",
    maxWidth: "100%",
    width: 6,
    transition: "opacity 200ms ease 0s",
    opacity: 0,
    background: "transparent",
    bottom: 2,
    top: 2,
    borderRadius: 3,
    right: 0,
  };

  return <div style={{ ...style, ...trackStyle }} {...props} />;
};

export const renderThumb: React.FC<RenderThumbProps> = ({ style, ...props }) => {
  const thumbStyle: CSSProperties = {
    borderRadius: 15,
    background: "rgba(222, 222, 222, .1)",
  };

  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

export const renderView: React.FC<RenderViewProps & BoxProps> = ({ style, ...props }) => {
  const viewStyle: CSSProperties = {
    marginBottom: -22,
  };

  return (
    <Box
      me={{ base: "0px !important", lg: "-16px !important" }}
      style={{ ...style, ...viewStyle }}
      {...props}
    />
  );
};

import { Box, Portal, useDisclosure } from "@chakra-ui/react";
import React, { useEffect } from "react";
import AdminLayout from "../../layouts/admin/index";
import Navbar from "../../components/navbar/NavbarAdmin";
import EmployeeTable from "./EmployeeTable";
import { checkSubscription, getSubscriptionList, manageAccounts } from "../../services/user";
import { useAppDispatch } from "../../store/hooks";

const EmployeeDashboard: React.FC = () => {
  const { onOpen } = useDisclosure();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await getSubscriptionList(dispatch);
      await checkSubscription(dispatch);
      await manageAccounts(dispatch);
    };

    fetchData();
  }, [dispatch]);

  return (
    <AdminLayout>
      <Portal>
        <Box>
          <Navbar onOpen={onOpen} brandText={"Manage Employees"} />
        </Box>
      </Portal>
      <EmployeeTable />
    </AdminLayout>
  );
};

export default EmployeeDashboard;

import React from "react";
import { Box, Button, Image, Text, IconButton, Spinner, useColorModeValue } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

interface SubscriptionPlanProps {
  name: string;
  description: string;
  amount: number;
  onSubscribe: () => void;
  // onManageAccount: () => void;
  imageUrl: string;
  id: number;
  bgColor?: string;
  boxShadowColor?: string;
  isPaid?: boolean;
  isPaidOne?: boolean;
  isLoader?: boolean;
  styleTop?: string;
  features?: string[];
  expiryDate?: string;
  isTrial?: boolean;
  planType?: string;
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({
  id,
  name,
  description,
  amount,
  onSubscribe,
  // onManageAccount,
  imageUrl,
  bgColor = "white",
  boxShadowColor = "white",
  isPaid = false,
  isLoader = false,
  isPaidOne = false,
  styleTop = "0rem",
  features = [],
  expiryDate = "2024-01-12",
  isTrial = true,
  planType = "default",
}) => {
  const currentDate: Date = new Date();
  const specifiedDate: Date = new Date(expiryDate);

  const timeDifference: number = specifiedDate.getTime() - currentDate.getTime();
  const daysDifference: number = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  const colorMode = useColorModeValue("light", "dark");
  return (
    <Box transition="transform 0.2s, box-shadow 0.2s">
      <Box
        key={id}
        p="3"
        borderRadius="50px"
        margin={"20px 5px"}
        bg={bgColor}
        width={250}
        color="white"
        cursor={"pointer"}
        _hover={{
          transform: "scale(1.05)",
          boxShadow: isPaid ? `-3px 1px 10px 3px #9d7070` : "lg",
          bgColor: "brand.200",
        }}
        height={540}
        textAlign="center"
        position="relative"
        boxShadow={isPaid ? (colorMode === "light" ? `-3px 1px 10px 3px #9d7070` : "md") : "md"}
      >
        <Image
          padding={id === 0 ? "20px 65px 33px 68px" : "0px 0px 0px 23px"}
          src={imageUrl}
          alt={`${name} Image`}
        />
        {isPaid && (
          <IconButton
            icon={<CheckCircleIcon />}
            color={colorMode === "light" ? "green.500" : "black.500"}
            bg={"none"}
            position="absolute"
            top="2"
            left="3"
            size="md"
            aria-label="Subscription Taken"
          />
        )}
        <Text fontSize="xl" fontWeight="500" letterSpacing={"2px"}>
          {name}
        </Text>
        {features?.length !== 0 && <Text
          display={"flex"}
          justifyContent={"center"}
          alignItems={"baseline"}
          fontSize="4xl"
          fontWeight={600}
          top={styleTop}
        >
          ${amount}
          <Text fontSize="16">/month</Text>
        </Text>}

        {/* <Text mt="1">{description}</Text> */}
        {features?.length !== 0 && <Text mt="1" fontWeight="500">
          Features:
        </Text>}
        {features.length === 0 ? (
              <Box textAlign="center" mt={14}>
          <Text  textAlign="center" fontSize="sm">
          Please contact us at <a href="mailto:contact@avsono.com">contact@avsono.com</a>
        </Text>
        </Box>
        ) : (
        <Box mt="3" ml={2} textAlign="left" fontSize="sm">
          <ul>
            {features.map((feature, index) => (
              <li style={{ marginTop: "4px" }} key={index}>
                {feature}
              </li>
            ))}
          </ul>
        </Box>
      )}
        {isPaidOne && isPaid && (
          <Text
            fontSize="md"
            mt="2"
            fontWeight={"600"}
            color={"white"}
            position="absolute"
            top={daysDifference === 0 ? "9" : "12"}
            right="-2"
            letterSpacing={2}
            transform="rotate(45deg)"
          >
            Expires{" "}
            {daysDifference === 0
              ? "Today"
              : daysDifference === 1
              ? "Tomorrow"
              : `in ${daysDifference} days`}
          </Text>
        )}
        {id === 0 && !isTrial && (
          <Text
            fontSize="lg"
            mt="2"
            fontWeight={"600"}
            color={colorMode === "light" ? "black" : "white"}
            position="absolute"
            top={"5"}
            right="0"
            letterSpacing={2}
            transform="rotate(45deg)"
          >
            Expired
          </Text>
        )}
      </Box>
      <Box textAlign="center" position="relative">
        {isPaidOne ? (
          <>
            {isPaid && (
              <Text
                transform="translate(55%, -190%)"
                fontSize={"xl"}
                color={"white"}
                fontWeight={700}
                position="absolute"
                letterSpacing={2}
              >
                Subscribed
              </Text>
            )}
          </>
        ) : (
          <>
            {isTrial ? (
              <>
                <Button
                  variant={colorMode === "light" ? "light" : ""}
                  fontWeight={600}
                  boxShadow={colorMode === "light" ? `0px 0px 6px 1px ${boxShadowColor}` : "none"}
                  borderRadius={3}
                  transform="translate(-50%, -100%)"
                  position="absolute"
                  bg={colorMode === "light" ? "white" : "black"}
                  _hover={{
                    color: colorMode === "light" ? boxShadowColor : "none",
                    fontWeight: "600",
                  }}
                  onClick={onSubscribe}
                  letterSpacing={2}
                >
                  {planType === "default" ? "GET STARTED" : "RENEW"} {isLoader && <Spinner />}
                </Button>
              </>
            ) : (
              id !== 0 && (
                <Button
                  variant={colorMode === "light" ? "light" : ""}
                  fontWeight={600}
                  boxShadow={colorMode === "light" ? `0px 0px 6px 1px ${boxShadowColor}` : "none"}
                  borderRadius={3}
                  transform="translate(-50%, -100%)"
                  position="absolute"
                  bg={colorMode === "light" ? "white" : "black"}
                  _hover={{
                    color: colorMode === "light" ? boxShadowColor : "none",
                    fontWeight: "600",
                  }}
                  onClick={onSubscribe}
                  letterSpacing={2}
                >
                  {planType === "default" ? "GET STARTED" : "RENEW"} {isLoader && <Spinner />}
                </Button>
              )
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionPlan;

// "_id": "65d72fd70f98d6bf12a6185f",
// "name": "logi",
// "email": "logitest@yopmail.com",
// "password": "$2b$10$DKdiW6/KotBXNMplxs/cYOeJUHMToiZt670OGoHF84zHVV9ii5UKO",
// "phone": "+911234567896",
// "address": "hm",
// "registrationNumber": "091",
// "natureOfBusiness": "it",
// "countryCode": "91",
// "regionCode": "France",
// "emailVerifiedAt": null,
// "areRidesAllowed": false,
// "createdAt": "2024-02-22T11:28:23.949Z",
// "updatedAt": "2024-02-22T11:28:23.949Z",

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: {
  name: string;
  email: string;
  phone: string;
  address: string;
  registrationNumber: string;
  natureOfBusiness: string;
  countryCode: string;
  regionCode: string;
  areRidesAllowed: boolean;
  paymentMethods: string[];
} = {
  name: "",
  email: "",
  phone: "",
  address: "",
  registrationNumber: "",
  natureOfBusiness: "",
  countryCode: "",
  regionCode: "",
  areRidesAllowed: false,
  paymentMethods: [],
};

const buisnssInfoSlice = createSlice({
  name: "businessInfo",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.address = action.payload;
    },
    setRegistrationNumber: (state, action: PayloadAction<string>) => {
      state.registrationNumber = action.payload;
    },
    setNatureOfBusiness: (state, action: PayloadAction<string>) => {
      state.natureOfBusiness = action.payload;
    },
    setCountryCode: (state, action: PayloadAction<string>) => {
      state.countryCode = action.payload;
    },
    setRegionCode: (state, action: PayloadAction<string>) => {
      state.regionCode = action.payload;
    },
    setAreRidesAllowed: (state, action: PayloadAction<boolean>) => {
      state.areRidesAllowed = action.payload;
    },
    setPaymentMethods: (state, action: PayloadAction<string[]>) => {
      state.paymentMethods = action.payload;
    },
  },
});

export const {
  setName,
  setEmail,
  setPhone,
  setAddress,
  setRegistrationNumber,
  setNatureOfBusiness,
  setCountryCode,
  setRegionCode,
  setAreRidesAllowed,
  setPaymentMethods,
} = buisnssInfoSlice.actions;

export default buisnssInfoSlice.reducer;

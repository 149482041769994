// Chakra Imports
import {
  Avatar,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useEditable,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "../sidebar/Sidebar";
import PropTypes from "prop-types";
// Assets
import routes from "../../routes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import { useEffect } from "react";

interface HeaderLinksProps {
  variant?: string;
  fixed?: boolean;
  secondary?: boolean;
  onOpen?: () => void;
}

export default function HeaderLinks({
  variant,
  fixed,
  secondary,
  onOpen,
}: HeaderLinksProps): JSX.Element {
  const navigate = useNavigate();
  const businessInfo = useAppSelector((state: RootState) => state.persist.businessInfo);
  const { url } = useAppSelector((state: RootState) => state.persist.subs);

  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const handleProfile = () => {
    navigate("/profile");
  };
  const handleManageAccount = () => {
    window.location.href = url;
  };
  useEffect(()=>{
    console.log("inside nav bar" +businessInfo.name);
  },[])
  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
    >
      <SidebarResponsive routes={routes} />
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: "pointer" }}
            color="white"
            name={businessInfo?.name }
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {businessInfo?.name}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
              <Text fontSize="sm" onClick={handleProfile}>
                Profile Info
              </Text>
            </MenuItem>
            <MenuItem _hover={{ bg: "none" }} _focus={{ bg: "none" }} borderRadius="8px" px="14px">
              <Text fontSize="sm" onClick={handleManageAccount}>
                Manage Accounts
              </Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};

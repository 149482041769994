import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Graphana {
    id: string,
    appName: string,
    prometheusUrl: string,
    grafanaUrl: string,
    pushProxyUrl: string
    config: Configuration
    status:string
  }
  
  interface Configuration {
    field1: string;
    field2: string;
    field3: string;
  }


export interface InfraState {
  infra: Graphana | any;
  loading: boolean;
}

const initialState: InfraState = {
  infra: null,
  loading: false,
} as const;

const infraSlice = createSlice({
  name: 'infraUsers',
  initialState,
  reducers: {
    fetchInfraDataStart: (state) => {
      state.loading = true;
    },
    fetchInfraData: (state, action: PayloadAction<any>) => {
      state.infra = action.payload;
      state.loading = false;
    },
    fetchInfraDataFailure: (state) => {
      state.loading = false;
    },
    fetchInfraDataReset: (state) => {
      state.loading = false;
      state.infra = null;
    },
  },
});


export const { fetchInfraDataStart, fetchInfraDataReset, fetchInfraData, fetchInfraDataFailure } = infraSlice.actions;
export default infraSlice.reducer;


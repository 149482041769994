import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

interface ConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLogoutModal: boolean;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isLogoutModal,
}) => {
  const colorMode = useColorModeValue("light", "dark");
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{isLogoutModal ? "Confirm Logout" : "Confirm Card Delete"}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isLogoutModal
            ? "Are you sure you want to logout"
            : "The card may be assigned to some of your employees as their payment method. Are you sure you want to delete ?"}
        </ModalBody>
        <ModalFooter>
          <Button
            color={colorMode === "light" ? "black" : "white"}
            _hover={{ color: "red" }}
            onClick={onConfirm}
            bg={"none"}
            _focus={{ boxShadow: "none" }}
          >
            Yes
          </Button>
          <Button
            color={colorMode === "light" ? "black" : "white"}
            _hover={{ color: "green.500" }}
            onClick={onClose}
            bg={"none"}
            _focus={{ boxShadow: "none" }}
          >
            No
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;

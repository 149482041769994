import React, { useState } from 'react'
import DefaultAuth from "../../../layouts/auth/default";
import illustration from "../../../assets/images/auth/logo_auth.jpg";
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    Text,
    useColorModeValue,
    useMediaQuery,
} from "@chakra-ui/react";
import { useAppDispatch } from '../../../store/hooks';
import { useSignInForm, useValidateOTPForm } from '../../../utils/forms';
import { RiEyeLine, RiEyeOffFill } from 'react-icons/ri';
import { NavLink } from 'react-router-dom';


export default function ValidateOTP() {
    const textColor = useColorModeValue("navy.700", "white");
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("red", "red");
    const isMobile = useMediaQuery("(max-width: 600px)");
    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);
    const dispatch = useAppDispatch();
    const formik = useValidateOTPForm(isMobile[0],dispatch);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const textColorSecondary = "gray.400";

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setFormSubmitted(true);
        formik.handleSubmit(e as any);
    };

    return (
        <DefaultAuth illustrationBackground={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column"
            >
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Sign In
                    </Heading>
                    <Text
                        mb="36px"
                        ms="4px"
                        color={textColorSecondary}
                        fontWeight="400"
                        fontSize="md"
                    >
                        Enter your OTP to sign in!
                    </Text>
                </Box>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}
                >
                    <form onSubmit={handleSubmit}>
                        <FormControl>
                            <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                            >
                                OTP<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Input
                                variant="auth"
                                fontSize="sm"
                                ms={{ base: "0px", md: "0px" }}
                                type="text"
                                placeholder="OTP"
                                mb={formSubmitted && formik.errors.otp ? "0px" : "24px"}
                                fontWeight="500"
                                size="lg"
                                value={formik.values.otp}
                                onChange={formik.handleChange("otp")}
                            />
                            {formSubmitted && formik.errors.otp && (
                                <Text
                                    marginBottom={"24px"}
                                    marginLeft={"10px"}
                                    fontSize={14}
                                    color="red"
                                >
                                    {formik.errors.otp}
                                </Text>
                            )}
                            <Button
                                fontSize="sm"
                                variant="brand"
                                fontWeight="500"
                                w="100%"
                                h="50"
                                mb="24px"
                                type="submit"
                                disabled={formik.isSubmitting}
                            >
                                {formik.isSubmitting ? 'Signing In' : 'Sign In'} {formik.isSubmitting && <Spinner />}
                            </Button>
                        </FormControl>
                    </form>
                    <Flex
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        maxW="100%"
                        mt="0px"
                    >
                        {/* <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                            Not registered yet?
                            <NavLink to="/sign-up">
                                <Text
                                color={textColorBrand}
                                as="span"
                                ms="5px"
                                fontWeight="500"
                                >
                                Create an Account
                                </Text>
                            </NavLink>
                        </Text> */}
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

import { Box, Flex, Stack } from "@chakra-ui/react";
import React from "react";
import Brand from "./Brand";
import Links from "./Links";
import SidebarCard from "./SidebarCard";

interface SidebarContentProps {
  routes: any[];
}

const SidebarContent: React.FC<SidebarContentProps> = (props) => {
  const { routes } = props;

  return (
    <Flex direction="column" height="100%" borderRadius="30px">
      <Box marginRight={"0px"} marginLeft={"-16px"}>
        <Brand />
      </Box>
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>

      <Box mt="60px" mb="40px" borderRadius="30px">
        <SidebarCard />
      </Box>
    </Flex>
  );
};

export default SidebarContent;

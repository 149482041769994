import { Icon } from "@chakra-ui/react";
import { MdPerson } from "react-icons/md";
import SubscriptionDashboard from "./pages/Subscription";
import EmployeeDashboard from "./pages/Employee";
import CardDashBoard from "./pages/Card/index";

const routes = [
  {
    name: "Manage Employees",
    layout: "/employee",
    path: "/employee",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: EmployeeDashboard,
  },
  {
    name: "Subscription",
    layout: "/admin",
    path: "/subscription",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: SubscriptionDashboard,
  },
  {
    name: "Manage Cards",
    layout: "/admin",
    path: "/manage-cards",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: CardDashBoard,
  },
];

export default routes;

import { combineReducers } from 'redux';
import authReducer from './authSlice';
import subscriptionReducer from './subsriptionSlice';
import infraReducer from './infraSlice';
import businessInfoReducer from '../slices/BusinessSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  subs: subscriptionReducer,
  infra: infraReducer,
  businessInfo: businessInfoReducer
});

export default rootReducer;

import { SystemStyleObject } from "@chakra-ui/styled-system";

interface LinkStyles {
  components: {
    Link: {
      baseStyle: SystemStyleObject;
      _hover: SystemStyleObject;
    };
  };
}

export const linkStyles: LinkStyles = {
  components: {
    Link: {
      baseStyle: {
        textDecoration: "none",
        boxShadow: "none",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
        _hover: {
          textDecoration: "none",
          border: "none",
        },
      },
      _hover: {
        textDecoration: "none",
        border: "none",
      },
    },
  },
};

import React from "react";
import { Box, Portal, useDisclosure } from "@chakra-ui/react";
import AdminLayout from "../../layouts/admin/index";
import Navbar from "../../components/navbar/NavbarAdmin";
import RidesTable from "./RidesTable";

const EmployeeRidesDashboard: React.FC = () => {
  const { onOpen } = useDisclosure();

  return (
    <AdminLayout>
      <Portal>
        <Box>
          <Navbar onOpen={onOpen} brandText={"Rides History"} />
        </Box>
      </Portal>
      <RidesTable />
    </AdminLayout>
  );
};

export default EmployeeRidesDashboard;

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Select,
  Text,
  Image,
  useColorModeValue, useMediaQuery
} from "@chakra-ui/react";
import DefaultAuth from "../../../layouts/auth/default";
// import illustration from "../../../assets/images/auth/logo_auth.jpg";
import illustration from "../../../assets/images/main/TeracarLogo.png";
import { RiEyeLine, RiEyeOffFill } from "react-icons/ri";
import { useSignUpForm } from "../../../utils/forms";
import { useAppDispatch } from "../../../store/hooks";
import { getCities, getCountries } from "../../../services/miscellaneous";
import axios from "axios";
import { Console } from "console";
import FixedPlugin from "../../../components/fixedPlugin/FixedPlugin";

interface Country {
  country_code: string;
  country_name: string;
}
interface Region{
  city_name: string,
  value: string,
  country_code: string,
}
function SignUp() {
  const phoneCountryCodes = ['+18', '+44', '+91', '+234', '+94'];
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("red", "red");
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const dispatch = useAppDispatch();
  const formik = useSignUpForm(isMobile[0], dispatch);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [countryData, setCountryData] = useState<string[]>();
  const [selectedCountry, setSelectedCountry] = useState();
  const [cityData, setCityData] = useState<{ city_name: string, value: string }[]>();
  const handleClick = () => setShow(!show);
  const handleConfirmClick = () => setShowConfirm(!showConfirm);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormSubmitted(true);
    formik.handleSubmit(e as any);
    console.log(e);
    console.log("e")
  };

  const countries = [
    {
      country_code: "UK",
      country_name: "United Kingdom",
      value: 'united-kingdom'
    },
    {
      country_code: "France",
      country_name: "France",
      value: 'france'
    },
  ];
  const region = [
    {
      city_name: "Ile De France",
      value: 'ile-de-france',
      country_code: "France",
    },
    {
      city_name: "London boroughs",
      value: 'london-boroughs',
      country_code: "UK",
    },
  ]


  useEffect(() => {
    const fetchData = async () => {
      const data = await getCountries() || [];
      // data?.forEach(element => {

      // });
      // setCountryData(data);
      // const countryData: any = data.filter((item: any) => item?.country_code === "FR" || item.country_code == "GB");
      // console.log("code" + JSON.stringify(countryData));
      setCountryData(countryData);
    };
    fetchData();
    setCityData(region);
  }, []);
  useEffect(() => {
    if (formik.values.countryCode === "france") {
      setCityData([{ city_name: "Ile De France", value: 'ile-de-france' }]);
    } else if (formik.values.countryCode === "united-kingdom") {
      setCityData([{ city_name: "London boroughs", value: 'london-boroughs' }]);
    } else {
      setCityData([]);
    }
    console.log(formik.values.countryCode);
  }, [formik.values.countryCode]);

  useEffect(() => {
    console.log(formik.values.regionCode);
  }, [formik.values.regionCode]);

  return (
    <Flex position="relative" h="max-content">
    <Flex
      // h={{
      //   sm: "initial",
      //   md: "unset",
      //   lg: "100vh",
      //   xl: "97vh",
      // }}
      h="auto"
      w="100%"
      maxW={{ md: "66%", lg: "1313px" }}
      mx="auto"
      pt={{ sm: "50px", md: "0px" }}
      px={{ lg: "30px", xl: "0px" }}
      ps={{ xl: "70px" }}
      justifyContent="start"
      direction="column"
    >
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Create an Account
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Fill up your details and create an account
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Company Name <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter your company name"
                mb={
                  formSubmitted && formik.errors.name ? "0px" : "24px"
                }
                fontWeight="500"
                size="lg"
                value={formik.values.name}
                onChange={formik.handleChange('name')}
                onBlur={formik.handleBlur}
              />
              {formSubmitted && formik.errors.name && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.name}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Phone Number <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="lg">
                <InputLeftAddon
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  mb={
                    formSubmitted && formik.errors.phone ? "24px" : "24px"
                  }
                  fontWeight="500"
                >
                  <Select
                    fontSize="sm"
                    variant="auth"
                    defaultValue={"+91"}
                    fontWeight="500"
                    size="lg"
                    border="none"
                    value={formik.values.phoneCountryCode}
                    onChange={(e) => formik.setFieldValue('phoneCountryCode', e.target.value)}
                  >
                    {phoneCountryCodes.map((code) => (
                      <option key={code} value={code} disabled={formik.values.phoneCountryCode === code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                </InputLeftAddon>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="number"
                  placeholder="9876543210"
                  mb={
                    formSubmitted && formik.errors.phone ? "0px" : "24px"
                  }
                  fontWeight="500"
                  size="lg"
                  value={formik.values.phone}
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur}
                />
              </InputGroup>
              {formSubmitted && formik.errors.phone && (
                <Text marginBottom={"24px"} marginTop={'-24px'} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.phone}
                </Text>
              )}
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                placeholder="mail@gmail.com"
                mb={
                  formSubmitted && formik.errors.email ? "0px" : "24px"
                }
                fontWeight="500"
                size="lg"
                value={formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur}
              />
              {formSubmitted && formik.errors.email && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.email}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Nature of Business <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter Nature Of Business"
                mb={
                  formSubmitted && formik.errors.natureOfBusiness ? "0px" : "24px"
                }
                fontWeight="500"
                size="lg"
                value={formik.values.natureOfBusiness}
                onChange={formik.handleChange('natureOfBusiness')}
                onBlur={formik.handleBlur}
              />
              {formSubmitted && formik.errors.natureOfBusiness && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.natureOfBusiness}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Registration Number<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter Registration Number Of Business"
                mb={
                  formSubmitted && formik.errors.registrationNumber ? "0px" : "24px"
                }
                fontWeight="500"
                size="lg"
                value={formik.values.registrationNumber}
                onChange={formik.handleChange('registrationNumber')}
                onBlur={formik.handleBlur}
              />
              {formSubmitted && formik.errors.registrationNumber && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.registrationNumber}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Country <Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                fontSize="sm"
                variant="auth"
                defaultValue={""}
                fontWeight="500"
                size="lg"
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="15"
                mb={formSubmitted && formik.errors.countryCode ? "0px" : "24px"}
                value={formik.values.countryCode}
                onChange={(e) => formik.setFieldValue('countryCode', e.target.value.replace(/\s+/g, '-').toLowerCase())}
              >
                {countries?.map((country, index) => (
                  <option
                    key={index}
                    value={(country as unknown as Country).country_name.toLowerCase()}
                    disabled={formik.values.countryCode === (country as unknown as Country).country_name}
                  >
                    {(country as unknown as Country).country_name}
                  </option>
                ))}
              </Select>
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Region<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                fontSize="sm"
                variant="auth"
                defaultValue={""}
                fontWeight="500"
                size="lg"
                borderWidth="1px"
                borderColor="gray.300"
                borderRadius="15"
                mb={formSubmitted && formik.errors.regionCode ? "0px" : "24px"}
                value={formik.values.regionCode}
                onChange={(e) => 
                  formik.setFieldValue('regionCode', e.target.value)}
              >
                {cityData?.map((region, index) => (
                  <option
                    key={index}
                    value={(region as unknown as Region).value}
                    disabled={formik.values.regionCode === (region as unknown as Region).value}
                  >
                    {region.city_name}
                  </option>
                ))}
              </Select>
                          {/* <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Address <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Enter Address"
                mb={
                  formSubmitted && formik.errors.address ? "0px" : "24px"
                }
                fontWeight="500"
                size="lg"
                value={formik.values.address}
                onChange={formik.handleChange('address')}
                onBlur={formik.handleBlur}
              />
              {formSubmitted && formik.errors.address && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.address}
                </Text>
              )} */}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb={
                    formSubmitted && formik.errors.password ? "0px" : "24px"
                  }
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  value={formik.values.password}
                  onChange={formik.handleChange('password')}
                  onBlur={formik.handleBlur}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeLine : RiEyeOffFill}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {formSubmitted && formik.errors.password && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.password}
                </Text>
              )}
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  fontSize="sm"
                  placeholder="Re type Password"
                  mb={
                    formSubmitted && formik.errors.confirmPassword ? "0px" : "24px"
                  }
                  size="lg"
                  type={showConfirm ? "text" : "password"}
                  variant="auth"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange('confirmPassword')}
                  onBlur={formik.handleBlur}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={showConfirm ? RiEyeLine : RiEyeOffFill}
                    onClick={handleConfirmClick}
                  />
                </InputRightElement>
              </InputGroup>
              {formSubmitted && formik.errors.confirmPassword && (
                <Text marginBottom={"24px"} marginLeft={"10px"} fontSize={14} color="red">
                  {formik.errors.confirmPassword}
                </Text>
              )}
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="start"
                maxW="100%"
                mt="0px"
              >
                <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                  Already have an account ?
                  <NavLink to="/">
                    <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                      Sign In
                    </Text>
                  </NavLink>
                </Text>
              </Flex>
              <Button fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px" type="submit" mt='10px'>
                Register
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
      <Box
        display={{ base: "none", md: "block" }}
        h="100%"
        minH="100vh"
        w={{ lg: "50vw", "2xl": "44vw" }}
        position="absolute"
        right="0px"
        
      >
        <Flex
        
          justify="flex-start"
          align="start"
          w="100%"
          h="100%"
          bgSize="contain"
          bgPosition="50%"
          bgRepeat="no-repeat"
          bgColor="#0000D2"
          position="absolute"
          borderBottomLeftRadius={{ lg: "120px", xl: "200px" }}
        >
          <Image
          mt="50"
          src={illustration}
          />
        </Flex>
      </Box>
    </Flex>
    <FixedPlugin />
  </Flex>
    // </DefaultAuth>
  );
}

export default SignUp;
